import { IAvailsTimelineReportResults } from "app/+reporting/services/report.models";
import { SystemIndicators } from "rl-common/consts";
import { ICharacteristicMetaDataValue } from "rl-common/models/i-characteristic-meta-data-value";
import { ICharacteristicTemplate } from "rl-common/models/i-characteristic-template";
import { ICharacteristicValueGroup } from "rl-common/models/i-characteristic-value-group";
import { IEntitySearchDoc } from "rl-common/models/i-entity-search-doc";
import { IFacetResult } from "rl-common/models/i-facet-result";
import { IRecordTitle } from "rl-common/models/i-record-title";
import { IExtraGridColumnResults } from "rl-common/services/grid-view/models/i-extra-grid-column-results";
import { Observable } from "rxjs";

export interface ICatalogCriteria {
	label: string;
	solrLabel: string;
	options: ICatalogCriteriaOption[];
}

export interface ICatalogCriteriaOption {
	label: string;
	value: string;
	isSelected: boolean;
}
/** [label, optionsDict, selectedDict, isAllSelected] */
export type CatalogLovCriteriaState = [string, _.Dictionary<string>, _.Dictionary<boolean>, boolean];
/** [selectedIds, lovs] */
export type DimCriteriaState = [number[], ICharacteristicMetaDataValue[], ICharacteristicValueGroup[], { [key: number]: string[] }];
/** [startDate, EndDate, minDays, matchType] */
export type DatesCriteriaState = [string, string, number, number, boolean];
/** [isExclusive] */
export type ExclCriteriaState = [boolean];
/** [isWindowed] */
export type WindowingCriteriaState = [boolean];
/** [rollupDimId] */
export type RollupCriteriaState = [number];
/** [dimIdList, labelObject, excludedChecks] */
export type RightsDisplayCriteriaState = [number[], _.Dictionary<string>, _.Dictionary<boolean>];
/** [showAvailable, showPartial,showUnavailable] */
export type AvailabilityDisplayCriteriaState = [boolean, boolean, boolean];
/** [SearchOptions, IRecordTitles] */
export type CatalogCriteriaState = IRecordTitle[];
/** [scope_exclusivity, scope_dim1, scope_dim2, scope_dim3, scope_dim4, {"1":"dim1Label", "2":"dim2Label", "3":"dim3Label", "4", "dim4Label" }] */
export type AdvancedCriteriaState = [boolean, boolean, boolean, boolean, boolean, _.Dictionary<string>];
/**[template_ids[]] */
export type RightsTempletCriteriaState = [number[], ICharacteristicTemplate[]];

/** [gridViewId, selectedIds, lovs] */
export type RightsHistoryCriteriaState = [number, number[], ICharacteristicMetaDataValue[]];

/** [rightsActionCondition[], [reason text, template text, dim lim text, term lim text][]] */
export type RightsActionConditionCriteriaState = [RightsActionCondition[], [string, string, string, string][]];

/** [typeId] 0=>history 1=>actions */
export type RightsSummaryTemplateGroupCriteriaState = [number];

export type CriteriaState = unknown[] | CatalogLovCriteriaState | DimCriteriaState | DatesCriteriaState | ExclCriteriaState
	| WindowingCriteriaState | RollupCriteriaState | RightsDisplayCriteriaState | AdvancedCriteriaState | RightsTempletCriteriaState | RightsHistoryCriteriaState | RightsActionConditionCriteriaState | RightsSummaryTemplateGroupCriteriaState;


export interface IRightsExplorerCriteria<T extends CriteriaState> {
	key: string;
	type: RightsExplorerCriteriaType;
	label: string;
	getValue: (req: IRightsExplorerCriteriaRequest) => Observable<T>;
	setValue: (req: IRightsExplorerCriteriaRequest, value: T) => void;
	validate?: (value: T) => object;
}

export interface IRightsExplorerCriteriaRequest {
	startDate: string;
	endDate: string;
	isExclusive: boolean;
	isWindowed: boolean;
	rightSetTemplateIds: number[];
	rightsetHistoryTemplateIds: number[];
	rightsActionConditions: RightsActionCondition[];
	rightsHistoryViewId: number;
	dimIds: _.Dictionary<number[]>;
	catalogIds: number[];
	catalogFacets: _.Dictionary<string[]>;
	minDays: number;
	matchType: number;
	rollup: number;
	dimFormatFlag: number;
	dimShowFlag: number;
	truncateWindows: boolean;
	showAvailable: boolean;
	showPartial: boolean;
	showUnavailable: boolean;
	nonScopedFlags: number;
	collapseSequence: number[];
	summaryTemplateGroupCountType: number
}

export interface IRightsExplorerCriteriaDisplayInfo {
	selectedCatalogItems: IRecordTitle[];
	dimLabels: _.Dictionary<string[]>;
	catalogFacets: _.Dictionary<string[]>;
	displayAvails: string[];
	collapseSequence: string[];
	selectedRightSetChipValues: ICharacteristicMetaDataValue[];
	rightsHistoryViewName: string;
	rightsHistoryTemplateNames: string[];
}

export enum RightsExplorerCriteriaType {
	multiSelect,
	singleSelect,
	lov,
	exclusivity,
	dates,
	catalog,
	windowing,
	rollup,
	rightsDisplay,
	availabilityDisplay,
	advanced,
	rightsTemplates,
	rightsHistoryTemplates,
	rightsActions,
	rightsSummaryTemplateGroups
}

export interface IRightsExplorerRightsCriteria {
	label: string;
	type: RightsExplorerCriteriaType;
	dimId?: number;
}

export interface IRightsExplorerSavedCriteria {
	criteriaName: string;
	mode?: number;
	criteria: IRightsExplorerCriteriaRequest;
}

export const RightsDimensionIds: number[] = [SystemIndicators.Media, SystemIndicators.Territory, SystemIndicators.Language, SystemIndicators.Channel];


export interface AvailsListSummaryRow {
	assetId: number;
	avPercent: number;
	pavPercent: number;
	navPercent: number;
	windows: AvailsListWindowRow[];
}

export interface AvailsListWindowRow {
	hash: string;
	assetId: number;
	windowStart: string;
	windowEnd: string;
	dim1BranchValues: string[];
	dim2BranchValues: string[];
	dim3BranchValues: string[];
	dim4BranchValues: string[];
	dim1Bits: number[];
	dim2Bits: number[];
	dim3Bits: number[];
	dim4Bits: number[];
	isExclusive: boolean;
	availStatusId: number;
	unavailReasonId: number;
	tupleState: number;
	hasDesc: boolean;
	summaryTemplateGroups: Record<number, { templateId: number, count: number }[]>;
}

export enum AvailStatusIds {
	available = 2,
	partial = 1,
	unavailable = 0
}

export interface IAvailsListReportResults<T> {

	availsRows: T[];
	searchDocs: IEntitySearchDoc[];
	extraGridColumnResults: IExtraGridColumnResults;
	numFound: number;
}

export enum AvailsReportColumnNames {
	TermStart = "term_start",
	TermEnd = "term_end",
	IsExclusive = "exclusive",
	Dim1Labels = "dim1_labels",
	Dim2Labels = "dim2_labels",
	Dim3Labels = "dim3_labels",
	Dim4Labels = "dim4_labels",
	Available = "available",
	UnavailableReason = "unavail_reason",
	TupleState = "tuple_state",
	CreatedBySequencing = "created_by_seq",
	SummaryTemplateGroup1 = "sum_tg_1",
	SummaryTemplateGroup2 = "sum_tg_2",
	SummaryTemplateGroup3 = "sum_tg_3",
	SummaryTemplateGroup4 = "sum_tg_4",
	SummaryTemplateGroup5 = "sum_tg_5"
}

export enum UnavailReasonIds {
	NoRightsIn = 1 << 0,
	NoRightsInEx = 1 << 1,
	RightsOut = 1 << 2,
	RightsOutEx = 1 << 3,
	ExactMismatch = 1 << 4,
	LimitedByClearance = 1 << 5,
	LimitedByChildren = 1 << 6,
	InsufficientReapply = 1 << 7,
	PartialMatchDim1 = 1 << 8,
	PartialMatchDim2 = 1 << 9,
	PartialMatchDim3 = 1 << 10,
	PartialMatchDim4 = 1 << 11,
	MinDays = 1 << 12,
	SearchRange = 1 << 13,
	NotWindowed = 1 << 14,
	LimitedByOverrun = 1 << 15,
	LimitedByChildrenNoRightsIn = 1 << 16
}

export enum AvailsStorageFlags {
	IsMatched = 1 << 0,
	IsAvailable = 1 << 1,
	IsExclusive = 1 << 2,
	IsWindowed = 1 << 3,
	IsCalc = 1 << 4,
	IsIn = 1 << 5,
	IsOut = 1 << 6,
	IsClearanceRestricted = 1 << 7,
	IsDescendantRestricted = 1 << 8,
	IsReapplyIn = 1 << 9,
	IsReapplyOut = 1 << 10,
	IsOverrunOccluded = 1 << 11,
	AllDescAvailable = 1 << 12,
	AllDescExclusive = 1 << 13,
	SomeDescAvailable = 1 << 14,
	SomeDescExclusive = 1 << 15
}

export interface AvailsTimelineData {
	searchStartDate: Date;
	searchEndDate: Date;
	data: IAvailsTimelineReportResults;
}

export enum RightsExplorerReportView {
	CatalogList = 1,
	DealList = 2,
	RightsAvailsTimeline = 3,
	RightsAvailsWindow = 4,
	// RightsAvailsSummary = 5
}

export interface AvailsRights {
	documents: IEntitySearchDoc[],
	extraGridColumnResults: IExtraGridColumnResults,
	facetFields: _.Dictionary<IFacetResult[]>
}

export interface AvailsListResultTuple {
	sum?: AvailsListSummaryRow,
	win?: AvailsListWindowRow,
	doc: IEntitySearchDoc
}

export interface AvailsSnapshot {
	catalogId: number,
	catalogTitle: string,
	// 5
	termStart: string,
	// 6
	termEnd: string,
	// 10
	termOverrun: string,
	// 7
	exclusivity: boolean,
	// media 1
	dim1BranchIds: number[],
	// territory 
	dim2BranchIds: number[],
	// language 3
	dim3BranchIds: number[],
	// channel 4
	dim4BranchIds: number[]
}


export interface RightsActionCondition {
	unavailableReasonId: UnavailReasonIds,
	templateIds: number[],
	showDimLimitation: boolean,
	showTermLimitation: boolean;
}
